export default [
	{
		name: 'AWS Certified SysOps Administrator Associate SOA-C02',
		cert_id: 'UC-9b39463d-3789-4ef7-921a-093fc6977a00',
		skills: ['Cloud Computing', 'Amazon Web Services (AWS)', 'DevOps'],
	},
	{
		name: 'Jenkins: Jobs, Pipelines, CI/CD and DevOps for Beginners',
		cert_id: 'UC-bbe7eaba-dda8-46f1-8db5-1e8d29a9b4ba',
		skills: ['CI/CD', 'Jenkins', 'DevOps'],
	},
	{
		name: 'Apache Airflow: The Hands-On Guide',
		cert_id: 'UC-0dff06f7-9613-4158-b106-e2719b821b32',
		skills: ['Data Engineering', 'Apache Airflow'],
	},
	{
		name: 'Apache Spark 3 - Real-time Stream Processing using Python',
		cert_id: 'UC-33cc5ad0-b574-41d0-b073-9461a1a144ce',
		skills: ['Big Data', 'Apache Spark'],
	},
	{
		name: 'Argo CD Essential Guide for End Users with Practice',
		cert_id: 'UC-e1f3c366-9bd9-4a85-990d-65669a6b0759',
		skills: ['Argo CD', 'Continuous Integration and Continuous Delivery (CI/CD)', 'DevOps'],
	},
	{
		name: 'Build Responsive Real-World Websites with HTML and CSS',
		cert_id: 'UC-ba1c5b09-f3c6-43ed-b2d8-d488a8c83552',
		skills: ['HTML', 'CSS'],
	},
	{
		name: 'Certified Kubernetes Administrator (CKA)',
		cert_id: 'UC-6f2299bd-dda2-44d5-bfbf-5f732aff4919',
		skills: ['K8s', 'DevOps'],
	},
	{
		name: 'Complete Guide to Elasticsearch',
		cert_id: 'UC-a12fea83-86c1-49b4-a404-cc17afe9ea84',
		skills: ['Elastic Stack (ELK)', 'DevOps', 'Elasticsearch'],
	},
	{
		name: 'Data Processing with Logstash (and Filebeat)',
		cert_id: 'UC-c8af7a8c-cd12-43e3-92fb-f28784ff661b',
		skills: ['Elastic Stack (ELK)', 'DevOps', 'Logstash'],
	},
	{
		name: 'Data Visualization with Kibana',
		cert_id: 'UC-76e1e2c4-2195-46d2-b8c9-7c8b1a261591',
		skills: ['Elastic Stack (ELK)', 'Kibana', 'DevOps'],
	},
	{
		name: 'Data Warehouse Fundamentals for Beginners',
		cert_id: 'UC-ef60d63f-7e59-42dd-a77e-d0a8fa8191df',
		skills: ['Data Engineering', 'Data Warehousing', 'Extract, Transform, Load (ETL)'],
	},
	{
		name: 'Docker Mastery: with Kubernetes + Swarm',
		cert_id: 'UC-ab424da9-229a-402f-9865-a6097f80899a',
		skills: ['DevOps', 'K8s', 'Docker'],
	},
	{
		name: 'Kubernetes Certified Application Developer (CKAD)',
		cert_id: 'UC-a376ac99-191e-464d-96b1-98c075cac509',
		skills: ['K8s', 'DevOps'],
	},
	{
		name: 'Kubernetes for the Absolute Beginners',
		cert_id: 'UC-4f80e09c-e905-4b0b-aba3-5af00fe72105',
		skills: ['K8s', 'DevOps'],
	},
	{
		name: 'LangChain in Action: Develop LLM-Powered Applications',
		cert_id: 'UC-f4550731-8673-4d08-9601-7b6c7e896fe6',
		skills: ['ChatGPT', 'LangChain', 'Python (Programming Language)'],
	},
	{
		name: 'Modern Web Scraping with Python using Scrapy Splash Selenium',
		cert_id: 'UC-d3f18462-fc15-41f4-9aaa-4cf6be427de4',
		skills: ['Web Scraping'],
	},
	{
		name: "MongoDB - The Complete Developer's Guide",
		cert_id: 'UC-9900f262-8440-486a-a4d6-d08d29d293fc',
		skills: ['MongoDB'],
	},
	{
		name: 'NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)',
		cert_id: 'UC-33cc5ad0-b574-41d0-b073-9461a1a144ce',
		skills: ['NodeJS', 'Full-Stack Development', 'MongoDB'],
	},
	{
		name: 'Observability with Grafana, Prometheus, Loki, Alloy and Tempo',
		cert_id: 'UC-d678c266-6365-45f6-9e89-6f5742980a02',
		skills: ['DevOps', 'Grafana', 'Prometheus', 'Grafana Alloy', 'Grafana Loki', 'Grafana Tempo'],
	},
	{
		name: 'OpenShift for the Absolute Beginners - Hands-on',
		cert_id: 'UC-19618edd-aea1-4c7f-af09-d3fefa1924bf',
		skills: ['OpenShift', 'DevOps'],
	},
	{
		name: 'The Ultimate React Course 2024: React, Next.js, Redux & More',
		cert_id: 'UC-9cdc31ec-ad4d-4c9d-9525-daf2ea62eb8a',
		skills: ['React.js'],
	},
];
